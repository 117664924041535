import React, { createContext, useContext, useState, useEffect } from "react";
import { API_BASE_URL } from "../config";

const ClientContext = createContext();

export const ClientProvider = ({ children }) => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchClients = async () => {
    try {
      console.log("Fetching clients...");
      setLoading(true);
      setError(null);

      // Update the fetch URL to use the API base URL
      const response = await fetch(`${API_BASE_URL}/clients`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Add any additional headers like authentication if needed
        },
      });

      console.log("Response status:", response.status);

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error response:", errorText);
        throw new Error(`Failed to fetch clients: ${response.statusText}`);
      }

      const data = await response.json();
      console.log("Fetched clients data:", data);

      const processedData = data.map((client) => ({
        ...client,
        boilerplate: client.boilerplate || "",
        spokesperson_name: client.spokesperson_name || "",
        spokesperson_title: client.spokesperson_title || "",
        contact_info: client.contact_info || "",
        social_media_handles: client.social_media_handles || {},
        brand_guidelines: client.brand_guidelines || "",
      }));

      console.log("Processed client data:", processedData);
      setClients(processedData);

      // Restore last selected client from localStorage if available
      const savedClientId = localStorage.getItem("selectedClientId");
      if (savedClientId) {
        const savedClient = processedData.find((c) => c.id === savedClientId);
        if (savedClient) {
          console.log("Restoring selected client:", savedClient);
          setSelectedClient(savedClient);
        }
      }
    } catch (err) {
      console.error("Error in fetchClients:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Add function to create a new client
  const createClient = async (clientData) => {
    try {
      console.log("Creating new client:", clientData);
      const response = await fetch(`${API_BASE_URL}/clients`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(clientData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error creating client:", errorText);
        throw new Error(`Failed to create client: ${response.statusText}`);
      }

      const newClient = await response.json();
      console.log("Created new client:", newClient);

      // Update clients list
      setClients((prev) => [...prev, newClient]);
      return newClient;
    } catch (err) {
      console.error("Error in createClient:", err);
      throw err;
    }
  };

  // Add function to update a client
  const updateClient = async (clientId, clientData) => {
    try {
      console.log(`Updating client ${clientId}:`, clientData);
      const response = await fetch(`${API_BASE_URL}/clients/${clientId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(clientData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error updating client:", errorText);
        throw new Error(`Failed to update client: ${response.statusText}`);
      }

      const updatedClient = await response.json();
      console.log("Updated client:", updatedClient);

      // Update clients list
      setClients((prev) =>
        prev.map((client) => (client.id === clientId ? updatedClient : client))
      );

      // Update selected client if it's the one being updated
      if (selectedClient?.id === clientId) {
        setSelectedClient(updatedClient);
      }

      return updatedClient;
    } catch (err) {
      console.error("Error in updateClient:", err);
      throw err;
    }
  };

  useEffect(() => {
    console.log("ClientProvider mounted, fetching clients...");
    fetchClients();
  }, []);

  useEffect(() => {
    if (selectedClient?.id) {
      console.log("Saving selected client to localStorage:", selectedClient.id);
      localStorage.setItem("selectedClientId", selectedClient.id);
    }
  }, [selectedClient]);

  const value = {
    clients,
    selectedClient,
    setSelectedClient,
    loading,
    error,
    refreshClients: fetchClients,
    createClient,
    updateClient,
  };

  return (
    <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
  );
};

export const useClient = () => {
  const context = useContext(ClientContext);
  if (!context) {
    throw new Error("useClient must be used within a ClientProvider");
  }
  return context;
};
