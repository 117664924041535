// Create a new file for API methods
import { API_BASE_URL } from "./config";
import { io } from "socket.io-client";
import { WS_URL, WS_PATH } from "./config";

export const api = {
  // Client related API calls
  async createClient(clientData) {
    const response = await fetch(`${API_BASE_URL}/clients`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(clientData),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.detail || "Failed to create client");
    }

    return response.json();
  },

  async updateClient(id, clientData) {
    const response = await fetch(`${API_BASE_URL}/clients/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(clientData),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.detail || "Failed to update client");
    }

    return response.json();
  },

  async deleteClient(id) {
    const response = await fetch(`${API_BASE_URL}/clients/${id}`, {
      method: "DELETE",
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.detail || "Failed to delete client");
    }

    return response.json();
  },
};

let socket = null;

export const initializeWebSocket = () => {
  if (!socket) {
    const socketUrl = WS_URL;
    console.log("Connecting to WebSocket at:", socketUrl);

    socket = io(socketUrl, {
      path: WS_PATH,
      transports: ["websocket"],
      secure: true,
      rejectUnauthorized: false,
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      query: {},
      autoConnect: true,
      forceNew: true,
    });

    socket.on("connect", () => {
      console.log("WebSocket Connected Successfully");
    });

    socket.on("connect_error", (error) => {
      console.error("WebSocket Connection Error:", error);
      if (socket.io.opts.transports.indexOf("polling") === -1) {
        socket.io.opts.transports = ["polling", "websocket"];
        socket.connect();
      }
    });

    socket.on("disconnect", (reason) => {
      console.log("WebSocket Disconnected:", reason);
    });

    socket.on("error", (error) => {
      console.error("Socket Error:", error);
    });
  }
  return socket;
};

export const executeCrew = (executionData) => {
  const socket = getSocket();
  if (socket && socket.connected) {
    console.log("Sending crew execution data:", executionData);
    socket.emit("execute_crew", executionData);
    return true;
  } else {
    console.error("WebSocket not connected");
    return false;
  }
};

export const getSocket = () => {
  if (!socket) {
    return initializeWebSocket();
  }
  return socket;
};

export const cleanupWebSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};
