import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Box,
  Chip,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Divider,
} from "@mui/material";
import {
  Close,
  History,
  ContentCopy,
  Download,
  ErrorOutline,
  CheckCircleOutline,
} from "@mui/icons-material";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const HistoryViewer = ({ open, onClose }) => {
  const [history, setHistory] = useState([]);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchHistory = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_BASE_URL}/history`);
      const data = await response.json();
      setHistory(data);
    } catch (error) {
      console.error("Error fetching history:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchHistory();
    }
  }, [open]);

  const handleEntryClick = (entry) => {
    setSelectedEntry(entry);
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleString();
  };

  // Add this function after the other handlers and before the components
  const handleDownload = async (imagePath) => {
    try {
      const response = await fetch(`${API_BASE_URL}/${imagePath}`);
      if (!response.ok) throw new Error("Download failed");

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = imagePath;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      setError("Failed to download image");
      console.error("Download error:", err);
    }
  };

  const HistoryEntry = ({ entry }) => (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        {entry.title}
      </Typography>
      <Chip
        label={entry.status}
        color={entry.status === "completed" ? "success" : "error"}
        icon={
          entry.status === "completed" ? (
            <CheckCircleOutline />
          ) : (
            <ErrorOutline />
          )
        }
        sx={{ mb: 2 }}
      />
      <Typography variant="caption" display="block" gutterBottom>
        {formatDate(entry.timestamp)}
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Typography variant="subtitle1" gutterBottom>
        Content
      </Typography>
      <Typography variant="body2" paragraph>
        {entry.content}
      </Typography>

      {entry.notes && (
        <>
          <Typography variant="subtitle1" gutterBottom>
            Notes
          </Typography>
          <Typography variant="body2" paragraph>
            {entry.notes}
          </Typography>
        </>
      )}

      {entry.analysis && (
        <>
          <Typography variant="subtitle1" gutterBottom>
            Analysis
          </Typography>
          <Box sx={{ ml: 2 }}>
            <Typography variant="body2" gutterBottom>
              <strong>Tone:</strong> {entry.analysis.tone}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Target Audience:</strong> {entry.analysis.target_audience}
            </Typography>

            <Typography variant="body2" gutterBottom>
              <strong>Key Points:</strong>
            </Typography>
            <List dense>
              {entry.analysis.key_points.map((point, index) => (
                <ListItem key={index}>
                  <ListItemText primary={point} />
                </ListItem>
              ))}
            </List>

            <Typography variant="body2" gutterBottom>
              <strong>Generated Prompts:</strong>
            </Typography>
            {Object.entries(entry.analysis.content_structure)
              .filter(([key]) => key.startsWith("prompt_"))
              .map(([key, prompt]) => (
                <Box key={key} sx={{ mb: 1 }}>
                  <Typography variant="body2">
                    {prompt}
                    <IconButton
                      size="small"
                      onClick={() => navigator.clipboard.writeText(prompt)}
                    >
                      <ContentCopy fontSize="small" />
                    </IconButton>
                  </Typography>
                </Box>
              ))}
          </Box>
        </>
      )}

      {entry.generated_images && entry.generated_images.length > 0 && (
        <>
          <Typography variant="subtitle1" gutterBottom>
            Generated Images
          </Typography>
          <Grid container spacing={2}>
            {entry.generated_images.map((image, index) => (
              <Grid item xs={6} key={index}>
                <Card>
                  <CardMedia
                    component="img"
                    height="200"
                    image={`${process.env.REACT_APP_API_BASE_URL}/uploads/generated/${image.path}`}
                    alt={`Generated image ${index + 1}`}
                  />
                  <CardContent>
                    <Button
                      startIcon={<Download />}
                      size="small"
                      onClick={() => handleDownload(image.path)}
                    >
                      Download
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Box>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            <History sx={{ mr: 1 }} />
            Content Generation History
          </Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <List>
              {history.map((entry) => (
                <ListItem
                  key={entry.id}
                  button
                  onClick={() => handleEntryClick(entry)}
                  selected={selectedEntry?.id === entry.id}
                >
                  <ListItemText
                    primary={entry.title}
                    secondary={formatDate(entry.timestamp)}
                  />
                  <Chip
                    size="small"
                    label={entry.status}
                    color={entry.status === "completed" ? "success" : "error"}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={8}>
            {selectedEntry && <HistoryEntry entry={selectedEntry} />}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default HistoryViewer;
