// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.agents-page-container {
  scale: 0.9;
  max-width: 1400px;
  margin: auto;
  padding: 14px;
}

.page-title {
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 600;
  color: white;
}

/* Add shadow to all cards */
.input-card,
.output-card,
.MuiCard-root {
  background: transparent;

  margin-bottom: 24px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2) !important;
}

/* Add shadow to all input fields */
.MuiTextField-root,
.MuiSelect-root,
.MuiFormControl-root {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2) !important;
}

.special-instructions-field .MuiOutlinedInput-root {
  background-color: rgba(0, 0, 0, 0.04);
}

.output-card {
  height: 100%;
  min-height: 400px;
}

.settings-header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Add some padding to input fields for better shadow visibility */
.MuiInputBase-root {
  margin: 4px 0 !important;
}

/* Ensure buttons also have the shadow */
.MuiButton-root {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/AgentsPage.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,iBAAiB;EACjB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;;AAEA,4BAA4B;AAC5B;;;EAGE,uBAAuB;;EAEvB,mBAAmB;EACnB,mDAAmD;AACrD;;AAEA,mCAAmC;AACnC;;;EAGE,mDAAmD;AACrD;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA,kEAAkE;AAClE;EACE,wBAAwB;AAC1B;;AAEA,wCAAwC;AACxC;EACE,mDAAmD;AACrD","sourcesContent":[".agents-page-container {\n  scale: 0.9;\n  max-width: 1400px;\n  margin: auto;\n  padding: 14px;\n}\n\n.page-title {\n  margin-bottom: 24px;\n  font-size: 24px;\n  font-weight: 600;\n  color: white;\n}\n\n/* Add shadow to all cards */\n.input-card,\n.output-card,\n.MuiCard-root {\n  background: transparent;\n\n  margin-bottom: 24px;\n  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2) !important;\n}\n\n/* Add shadow to all input fields */\n.MuiTextField-root,\n.MuiSelect-root,\n.MuiFormControl-root {\n  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2) !important;\n}\n\n.special-instructions-field .MuiOutlinedInput-root {\n  background-color: rgba(0, 0, 0, 0.04);\n}\n\n.output-card {\n  height: 100%;\n  min-height: 400px;\n}\n\n.settings-header {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.form-container {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n}\n\n/* Add some padding to input fields for better shadow visibility */\n.MuiInputBase-root {\n  margin: 4px 0 !important;\n}\n\n/* Ensure buttons also have the shadow */\n.MuiButton-root {\n  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
