import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Chip,
} from "@mui/material";
import { TextFields, Check, Style } from "@mui/icons-material";

const TextOverlaySelector = ({
  open,
  onClose,
  image,
  content,
  onApplyOverlay,
}) => {
  const [textOptions, setTextOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [error, setError] = useState(null);

  const API_BASE_URL =
    process.env.REACT_APP_API_BASE_URL || "http://localhost:8000";

  useEffect(() => {
    if (open && content) {
      generateTextOptions();
    }
  }, [open, content]);

  const generateTextOptions = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/generate-overlay-text`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          content,
          image_context: image?.prompt,
        }),
      });

      if (!response.ok) throw new Error("Failed to generate text options");

      const data = await response.json();
      setTextOptions(data.options);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleApply = async () => {
    if (!selectedOption) return;

    try {
      const response = await fetch(`${API_BASE_URL}/apply-text-overlay`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          image_id: image.id,
          text: selectedOption,
          position: { x: 0.5, y: 0.5 }, // Center position
          style: {
            size: 48,
            color: "white",
            font: "Arial",
          },
        }),
      });

      if (!response.ok) throw new Error("Failed to apply overlay");

      const result = await response.json();
      onApplyOverlay(result);
      onClose();
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" gap={1}>
          <TextFields />
          <Typography>Select Text Overlay</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" p={4}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            {textOptions.map((option, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Card
                  onClick={() => setSelectedOption(option)}
                  sx={{
                    cursor: "pointer",
                    border: selectedOption === option ? 2 : 0,
                    borderColor: "primary.main",
                    "&:hover": { bgcolor: "action.hover" },
                  }}
                >
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {option.headline}
                    </Typography>
                    {option.subtext && (
                      <Typography variant="body2" color="text.secondary">
                        {option.subtext}
                      </Typography>
                    )}
                    <Box mt={1}>
                      <Chip
                        icon={<Style fontSize="small" />}
                        label={option.style}
                        size="small"
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleApply}
          disabled={!selectedOption || loading}
          startIcon={<Check />}
          variant="contained"
        >
          Apply Selected Text
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TextOverlaySelector;
