// Update these imports at the top of the file
import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Input,
  Button,
  Chip,
  Tabs,
  Tab,
  Box,
  Alert,
  Badge,
  AlertTitle,
  TextField,
  IconButton,
  Grid,
  Paper,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Search,
  Filter,
  Calendar,
  FileText,
  Users,
  Tag,
  BookOpen,
  Clock,
  Star,
  BarChart,
  Download,
  Share2,
  FolderOpen,
  History,
  Bookmark,
  Eye,
  Settings,
  Plus,
  ChevronRight,
  Archive,
  TrendingUp,
  MessageSquare,
  List,
  Save,
  ArrowUpDown,
  RefreshCw,
  AlertTriangle,
} from "lucide-react";
import debounce from "lodash/debounce";

// Add the data management layer
const useKnowledgeStore = () => {
  const [store, setStore] = useState({
    documents: [],
    history: [],
    bookmarks: [],
    tags: new Set(),
    clients: new Set(),
    categories: new Set(),
  });

  const searchIndex = {
    add: (document) => {
      setStore((prev) => ({
        ...prev,
        documents: [...prev.documents, document],
      }));
    },
    remove: (documentId) => {
      setStore((prev) => ({
        ...prev,
        documents: prev.documents.filter((doc) => doc.id !== documentId),
      }));
    },
    search: async (query, filters) => {
      let filteredDocs = store.documents;

      // Apply filters
      if (filters.type && filters.type.length > 0) {
        filteredDocs = filteredDocs.filter((doc) =>
          filters.type.includes(doc.type)
        );
      }
      if (filters.client && filters.client.length > 0) {
        filteredDocs = filteredDocs.filter((doc) =>
          filters.client.includes(doc.client)
        );
      }
      if (filters.tags && filters.tags.length > 0) {
        filteredDocs = filteredDocs.filter((doc) =>
          doc.tags.some((tag) => filters.tags.includes(tag))
        );
      }
      if (filters.status && filters.status.length > 0) {
        filteredDocs = filteredDocs.filter((doc) =>
          filters.status.includes(doc.status)
        );
      }
      if (filters.date && filters.date !== "all") {
        const now = Date.now();
        let dateThreshold;
        switch (filters.date) {
          case "last24h":
            dateThreshold = now - 24 * 60 * 60 * 1000;
            break;
          case "lastWeek":
            dateThreshold = now - 7 * 24 * 60 * 60 * 1000;
            break;
          case "lastMonth":
            dateThreshold = now - 30 * 24 * 60 * 60 * 1000;
            break;
          case "lastYear":
            dateThreshold = now - 365 * 24 * 60 * 60 * 1000;
            break;
          case "custom":
            // Implement custom date range logic if needed
            dateThreshold = 0;
            break;
          default:
            dateThreshold = 0;
        }
        filteredDocs = filteredDocs.filter(
          (doc) => new Date(doc.lastModified).getTime() >= dateThreshold
        );
      }

      const results = filteredDocs
        .filter(
          (doc) =>
            doc.title.toLowerCase().includes(query.toLowerCase()) ||
            doc.content.toLowerCase().includes(query.toLowerCase())
        )
        .map((doc) => ({
          ...doc,
          relevance: calculateRelevance(doc, query),
        }))
        .sort((a, b) => b.relevance - a.relevance);

      return results;
    },
  };

  const calculateRelevance = (document, query) => {
    let score = 0;
    const queryTerms = query.toLowerCase().split(" ");

    queryTerms.forEach((term) => {
      if (document.title.toLowerCase().includes(term)) score += 5;
      if (document.content.toLowerCase().includes(term)) score += 2;
    });

    const daysSinceModified =
      (Date.now() - new Date(document.lastModified)) / (1000 * 60 * 60 * 24);
    score += Math.max(0, 10 - daysSinceModified);

    return Math.min(score / 20, 1);
  };

  return {
    store,
    searchIndex,
    updateStore: setStore,
  };
};

// Add the advanced search hook
const useAdvancedSearch = (searchIndex, initialQuery = "") => {
  const [searchState, setSearchState] = useState({
    query: initialQuery,
    filters: {},
    sort: "relevance",
    view: "list",
    page: 1,
    resultsPerPage: 10,
  });

  const [results, setResults] = useState({
    items: [],
    total: 0,
    loading: false,
    error: null,
  });

  const debouncedSearch = useCallback(
    debounce(async (query, filters) => {
      setResults((prev) => ({ ...prev, loading: true }));
      try {
        const searchResults = await searchIndex.search(query, filters);
        setResults({
          items: searchResults,
          total: searchResults.length,
          loading: false,
          error: null,
        });
      } catch (error) {
        setResults({
          items: [],
          total: 0,
          loading: false,
          error: error.message,
        });
      }
    }, 300),
    [searchIndex]
  );

  useEffect(() => {
    if (searchState.query) {
      debouncedSearch(searchState.query, searchState.filters);
    } else {
      setResults({
        items: [],
        total: 0,
        loading: false,
        error: null,
      });
    }
  }, [searchState.query, searchState.filters, debouncedSearch]);

  return {
    searchState,
    setSearchState,
    results,
  };
};

// Add loading and error states
const LoadingState = () => (
  <div className="flex items-center justify-center p-8">
    <RefreshCw className="w-6 h-6 animate-spin" />
    <span className="ml-2">Loading results...</span>
  </div>
);

const ErrorState = ({ error }) => (
  <Alert variant="destructive">
    <AlertTriangle className="w-4 h-4" />
    <AlertTitle>{error}</AlertTitle>
  </Alert>
);

// Replace className styling with MUI sx prop
const StyledCard = styled(Card)(({ theme }) => ({
  cursor: "pointer",
  transition: "all 0.2s",
  "&:hover": {
    boxShadow: theme.shadows[4],
  },
}));

const KnowledgeDiscoverySystem = () => {
  const { store, searchIndex, updateStore } = useKnowledgeStore();
  const { searchState, setSearchState, results } =
    useAdvancedSearch(searchIndex);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [recentSearches, setRecentSearches] = useState([]);
  const [savedSearches, setSavedSearches] = useState([]);
  const [analytics, setAnalytics] = useState({
    topSearches: [],
    popularDocs: [],
    usage: {},
  });

  // Add analytics tracking
  useEffect(() => {
    const trackPageView = () => {
      console.log("Page viewed:", window.location.pathname);
    };
    trackPageView();
  }, []);

  // Add document interaction handlers
  const handleDocumentClick = useCallback(
    (document) => {
      setSelectedDocument(document);
      updateStore((prev) => ({
        ...prev,
        documents: prev.documents.map((doc) =>
          doc.id === document.id ? { ...doc, views: (doc.views || 0) + 1 } : doc
        ),
      }));
    },
    [updateStore]
  );

  const handleBookmark = useCallback(
    (document) => {
      updateStore((prev) => ({
        ...prev,
        bookmarks: prev.bookmarks.includes(document.id)
          ? prev.bookmarks.filter((id) => id !== document.id)
          : [...prev.bookmarks, document.id],
      }));
    },
    [updateStore]
  );

  // Knowledge Base Categories and Metadata
  const categories = {
    campaigns: {
      icon: TrendingUp,
      subcategories: ["Product Launches", "Crisis Response", "Brand Building"],
    },
    documents: {
      icon: FileText,
      subcategories: ["Templates", "Guidelines", "Policies"],
    },
    mediaRelations: {
      icon: MessageSquare,
      subcategories: ["Press Releases", "Media Lists", "Coverage Reports"],
    },
    research: {
      icon: BookOpen,
      subcategories: [
        "Market Analysis",
        "Competitor Research",
        "Industry Reports",
      ],
    },
  };

  // Advanced Search Configuration
  const searchConfig = {
    fields: ["title", "content", "tags", "metadata"],
    operators: ["AND", "OR", "NOT"],
    filters: {
      date: ["last24h", "lastWeek", "lastMonth", "lastYear", "all"],
      type: ["document", "template", "campaign", "report", "media"],
      status: ["active", "archived", "draft"],
    },
  };

  // Toggle filter function
  const toggleFilter = (filterType, value) => {
    setSearchState((prev) => {
      const currentValues = prev.filters[filterType] || [];
      let updatedValues;
      if (currentValues.includes(value)) {
        updatedValues = currentValues.filter((item) => item !== value);
      } else {
        updatedValues = [...currentValues, value];
      }
      return {
        ...prev,
        filters: {
          ...prev.filters,
          [filterType]: updatedValues,
        },
      };
    });
  };

  // AI-Enhanced Search and Analysis Components
  const SearchEnhancer = () => {
    const [suggestions, setSuggestions] = useState([]);
    const [relatedQueries, setRelatedQueries] = useState([]);

    const analyzeQuery = (query) => {
      // Simulate AI query analysis and enhancement
      return {
        enhancedQuery: `${query} AND (templates OR guidelines)`,
        suggestions: [
          "Add client filter for more specific results",
          "Include archived documents in search",
          "Sort by recent usage",
        ],
        related: ["similar campaigns", "best practices", "success metrics"],
      };
    };

    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box sx={{ position: "relative" }}>
          <Search
            sx={{
              position: "absolute",
              left: 8,
              top: 8,
              color: "text.secondary",
            }}
          />
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            placeholder="Search across all knowledge assets..."
            sx={{ "& .MuiOutlinedInput-root": { pl: 5 } }}
            value={searchState.query}
            onChange={(e) => {
              setSearchState((prev) => ({ ...prev, query: e.target.value }));
              const analysis = analyzeQuery(e.target.value);
              setSuggestions(analysis.suggestions);
              setRelatedQueries(analysis.related);
            }}
          />
        </Box>

        {suggestions.length > 0 && (
          <Card>
            <CardContent>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Search Suggestions
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                {suggestions.map((suggestion, index) => (
                  <Box
                    key={index}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <ChevronRight sx={{ mr: 1, color: "primary.main" }} />
                    <Typography variant="body2">{suggestion}</Typography>
                  </Box>
                ))}
              </Box>
            </CardContent>
          </Card>
        )}
      </Box>
    );
  };

  // Advanced Filter Panel
  const FilterPanel = () => {
    return (
      <Card className="w-64">
        <CardContent className="p-4">
          <h3 className="font-medium mb-4">Filters</h3>

          {/* Category Filter */}
          <div className="space-y-4">
            <div>
              <label className="text-sm font-medium">Categories</label>
              <div className="space-y-2 mt-2">
                {Object.entries(categories).map(([key, value]) => (
                  <div key={key} className="flex items-center">
                    <Button
                      variant="ghost"
                      className="w-full justify-start"
                      onClick={() => toggleFilter("type", key)}
                    >
                      <value.icon className="w-4 h-4 mr-2" />
                      {key}
                    </Button>
                  </div>
                ))}
              </div>
            </div>

            {/* Date Range Filter */}
            <div>
              <label className="text-sm font-medium">Time Period</label>
              <div className="space-y-2 mt-2">
                {searchConfig.filters.date.map((period) => (
                  <Button
                    key={period}
                    variant="ghost"
                    className="w-full justify-start capitalize"
                    onClick={() => toggleFilter("date", period)}
                  >
                    <Clock className="w-4 h-4 mr-2" />
                    {period.replace(/last/, "Last ")}
                  </Button>
                ))}
              </div>
            </div>

            {/* Status Filter */}
            <div>
              <label className="text-sm font-medium">Status</label>
              <div className="space-y-2 mt-2">
                {searchConfig.filters.status.map((status) => (
                  <Button
                    key={status}
                    variant="ghost"
                    className="w-full justify-start capitalize"
                    onClick={() => toggleFilter("status", status)}
                  >
                    <Tag className="w-4 h-4 mr-2" />
                    {status}
                  </Button>
                ))}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  };

  // Search Results Display
  const SearchResults = () => {
    const ResultCard = ({ item }) => (
      <StyledCard onClick={() => handleDocumentClick(item)}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Chip label={item.type} size="small" />
                <Typography variant="subtitle1">{item.title}</Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  color: "text.secondary",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Clock sx={{ mr: 0.5, fontSize: 16 }} />
                  <Typography variant="body2">
                    {new Date(item.lastModified).toLocaleDateString()}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Users sx={{ mr: 0.5, fontSize: 16 }} />
                  <Typography variant="body2">{item.client}</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Eye sx={{ mr: 0.5, fontSize: 16 }} />
                  <Typography variant="body2">{item.views} views</Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {item.tags.map((tag) => (
                  <Chip key={tag} label={tag} size="small" variant="outlined" />
                ))}
              </Box>
            </Box>

            <Box sx={{ display: "flex", gap: 1 }}>
              <IconButton
                size="small"
                color={
                  store.bookmarks.includes(item.id) ? "primary" : "default"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  handleBookmark(item);
                }}
              >
                <Bookmark />
              </IconButton>
              <IconButton size="small">
                <Share2 />
              </IconButton>
              <IconButton size="small">
                <Download />
              </IconButton>
            </Box>
          </Box>
        </CardContent>
      </StyledCard>
    );

    if (results.loading) {
      return <LoadingState />;
    }

    if (results.error) {
      return <ErrorState error={results.error} />;
    }

    if (results.items.length === 0) {
      return (
        <div className="p-4 text-center text-gray-500">
          No results found for "{searchState.query}"
        </div>
      );
    }

    return (
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <h3 className="font-medium">{results.total} Results</h3>
            <div className="flex items-center space-x-2">
              <Button
                variant={searchState.view === "list" ? "default" : "outline"}
                size="sm"
                onClick={() =>
                  setSearchState((prev) => ({ ...prev, view: "list" }))
                }
              >
                <List className="w-4 h-4" />
              </Button>
              <Button
                variant={searchState.view === "grid" ? "default" : "outline"}
                size="sm"
                onClick={() =>
                  setSearchState((prev) => ({ ...prev, view: "grid" }))
                }
              >
                <Grid className="w-4 h-4" />
              </Button>
            </div>
          </div>

          <div className="flex items-center space-x-2">
            <Button variant="outline" size="sm">
              <ArrowUpDown className="w-4 h-4 mr-2" />
              Sort
            </Button>
            <Button variant="outline" size="sm">
              <Save className="w-4 h-4 mr-2" />
              Save Search
            </Button>
          </div>
        </div>

        <div
          className={`grid ${
            searchState.view === "grid" ? "grid-cols-2" : "grid-cols-1"
          } gap-4`}
        >
          {results.items.map((item) => (
            <ResultCard key={item.id} item={item} />
          ))}
        </div>
      </div>
    );
  };

  // Analytics Dashboard
  const AnalyticsDashboard = () => {
    return (
      <Card>
        <CardContent className="p-4">
          <h3 className="font-medium mb-4">Knowledge Base Analytics</h3>

          <div className="grid grid-cols-3 gap-4 mb-6">
            <div className="p-4 bg-gray-50 rounded-lg">
              <div className="text-sm text-gray-500">Most Accessed</div>
              <div className="text-lg font-medium">Campaign Templates</div>
              <div className="text-sm text-gray-500">Last 30 days</div>
            </div>
            <div className="p-4 bg-gray-50 rounded-lg">
              <div className="text-sm text-gray-500">Top Search Term</div>
              <div className="text-lg font-medium">Crisis Response</div>
              <div className="text-sm text-gray-500">This week</div>
            </div>
            <div className="p-4 bg-gray-50 rounded-lg">
              <div className="text-sm text-gray-500">Knowledge Growth</div>
              <div className="text-lg font-medium">+24 Documents</div>
              <div className="text-sm text-gray-500">This month</div>
            </div>
          </div>

          <div className="space-y-4">
            <h4 className="font-medium">Trending Resources</h4>
            <div className="space-y-2">
              {analytics.popularDocs.slice(0, 5).map((doc, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-2 bg-gray-50 rounded"
                >
                  <div className="flex items-center">
                    <Badge className="mr-2">{index + 1}</Badge>
                    <span>{doc.title}</span>
                  </div>
                  <Badge variant="outline">{doc.views} views</Badge>
                </div>
              ))}
            </div>
          </div>
        </CardContent>
      </Card>
    );
  };
  // Main Layout
  const [activeTab, setActiveTab] = useState("search");

  return (
    <Box sx={{ maxWidth: "xl", mx: "auto", p: 3 }}>
      <Card>
        <CardHeader
          title={
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">Knowledge Discovery System</Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Button variant="outlined" size="small" startIcon={<History />}>
                  Recent
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<Settings />}
                >
                  Settings
                </Button>
              </Box>
            </Box>
          }
        />
        <CardContent>
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
          >
            <Tab label="Search" value="search" />
            <Tab label="Browse" value="browse" />
            <Tab label="Analytics" value="analytics" />
          </Tabs>

          <Box sx={{ mt: 2 }}>
            {activeTab === "search" && (
              <div className="space-y-6">
                <SearchEnhancer />

                <div className="flex gap-6">
                  <FilterPanel />
                  <div className="flex-1">
                    <SearchResults />
                  </div>
                </div>
              </div>
            )}

            {activeTab === "browse" && (
              <div className="grid grid-cols-2 gap-4">
                {Object.entries(categories).map(([key, category]) => (
                  <Card key={key}>
                    <CardContent>
                      <div className="flex items-center space-x-2 mb-4">
                        {category.icon && <category.icon className="w-6 h-6" />}
                        <Typography variant="h6" className="capitalize">
                          {key}
                        </Typography>
                      </div>
                      <div className="space-y-2">
                        {category.subcategories.map((sub) => (
                          <Button
                            key={sub}
                            variant="text"
                            size="small"
                            startIcon={<ChevronRight sx={{ fontSize: 16 }} />}
                            fullWidth
                            sx={{
                              justifyContent: "flex-start",
                              textTransform: "capitalize",
                            }}
                          >
                            {sub}
                          </Button>
                        ))}
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </div>
            )}

            {activeTab === "analytics" && <AnalyticsDashboard />}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default KnowledgeDiscoverySystem;
