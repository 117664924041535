import React from "react";
import { Box, RadioGroup, FormControlLabel, Radio, Alert } from "@mui/material";

const Step1Analysis = ({ formData, handleInputChange, errors }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <RadioGroup
        value={formData.analysisType}
        onChange={(e) => handleInputChange("analysisType", e.target.value)}
      >
        {[
          "Predictive Analysis",
          "Trend Analysis",
          "Statistical Analysis",
          "Custom Analysis",
        ].map((type) => (
          <FormControlLabel
            key={type}
            value={type}
            control={<Radio />}
            label={type}
            sx={{ mb: 1 }}
          />
        ))}
      </RadioGroup>
      {errors.analysisType && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {errors.analysisType}
        </Alert>
      )}
    </Box>
  );
};

export default Step1Analysis;
