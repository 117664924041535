import React from "react";
import {
  Box,
  Grid,
  TextField,
  FormGroup,
  Typography,
  FormControlLabel,
  Checkbox,
  Alert,
  Paper,
} from "@mui/material";

const Step3Report = ({
  formData,
  handleInputChange,
  handlePreferenceChange,
  errors,
  analysisResults,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            type="email"
            value={formData.email}
            onChange={(e) => handleInputChange("email", e.target.value)}
            placeholder="Enter your email for the report"
            variant="outlined"
          />
          <FormGroup sx={{ mt: 2 }}>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Report Preferences
            </Typography>
            {[
              "Include visualizations",
              "Include raw data",
              "Include recommendations",
            ].map((pref) => (
              <FormControlLabel
                key={pref}
                control={
                  <Checkbox
                    checked={formData.preferences.includes(pref)}
                    onChange={(e) =>
                      handlePreferenceChange(pref, e.target.checked)
                    }
                  />
                }
                label={pref}
              />
            ))}
          </FormGroup>
          {errors.email && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {errors.email}
            </Alert>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        {analysisResults && (
          <Paper elevation={2} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Analysis Summary
            </Typography>
            {/* Add your analysis results visualization here */}
            <Typography variant="body2">
              {JSON.stringify(analysisResults, null, 2)}
            </Typography>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
};

export default Step3Report;
