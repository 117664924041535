import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  TextField,
  Button,
  Paper,
  Avatar,
  Collapse,
  Slide,
  Divider,
  IconButton,
} from "@mui/material";
import {
  Send as SendIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from "@mui/icons-material";

const Message = ({ text, isUser, timestamp }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: isUser ? "flex-end" : "flex-start",
      mb: 2,
    }}
  >
    {!isUser && (
      <Avatar
        sx={{
          bgcolor: "primary.main",
          mr: 1,
        }}
      >
        D
      </Avatar>
    )}
    <Paper
      elevation={1}
      sx={{
        maxWidth: "70%",
        p: 2,
        bgcolor: isUser ? "primary.main" : "background.paper",
        color: isUser ? "primary.contrastText" : "text.primary",
        borderRadius: 2,
      }}
    >
      <Typography variant="body1">{text}</Typography>
      <Typography variant="caption" sx={{ opacity: 0.7 }}>
        {timestamp}
      </Typography>
    </Paper>
    {isUser && (
      <Avatar
        sx={{
          ml: 1,
        }}
      >
        U
      </Avatar>
    )}
  </Box>
);

const Step4Chat = ({ analysisResults }) => {
  const [showChat, setShowChat] = useState(false);
  const [messages, setMessages] = useState([
    {
      text: "Hi! I'm Dan, your AI analysis assistant. I've reviewed your analysis results. What would you like to know about them?",
      isUser: false,
      timestamp: new Date().toLocaleTimeString(),
    },
  ]);
  const [newMessage, setNewMessage] = useState("");
  const [showSteps, setShowSteps] = useState(true);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setShowSteps(false);
      setTimeout(() => setShowChat(true), 500);
    }, 1000);
  }, []);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      setMessages((prev) => [
        ...prev,
        {
          text: newMessage,
          isUser: true,
          timestamp: new Date().toLocaleTimeString(),
        },
      ]);
      // Simulate Dan's response
      setTimeout(() => {
        setMessages((prev) => [
          ...prev,
          {
            text: `I understand you're asking about "${newMessage}". Based on the analysis...`,
            isUser: false,
            timestamp: new Date().toLocaleTimeString(),
          },
        ]);
      }, 1000);
      setNewMessage("");
    }
  };

  return (
    <Box sx={{ position: "relative", height: "80vh" }}>
      <Collapse in={showSteps} collapsedSize={0}>
        <Card sx={{ bgcolor: "primary.light", mb: 2 }}>
          <CardContent>
            <Typography variant="h6" color="primary.contrastText">
              Transitioning to Chat...
            </Typography>
            <Typography variant="body2" color="primary.contrastText">
              Setting up your conversation with Dan
            </Typography>
          </CardContent>
        </Card>
      </Collapse>

      <Slide direction="up" in={showChat} mountOnEnter unmountOnExit>
        <Paper
          elevation={3}
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            bgcolor: "background.default",
            borderRadius: 2,
          }}
        >
          {/* Chat Header */}
          <Box
            sx={{
              p: 2,
              bgcolor: "primary.main",
              color: "primary.contrastText",
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            }}
          >
            <Typography variant="h6">Chat with Dan</Typography>
            <Typography variant="body2">AI Analysis Assistant</Typography>
          </Box>

          {/* Messages Area */}
          <Box
            sx={{
              flex: 1,
              overflow: "auto",
              p: 2,
              bgcolor: "background.default",
            }}
          >
            {messages.map((message, index) => (
              <Message key={index} {...message} />
            ))}
            <div ref={messagesEndRef} />
          </Box>

          {/* Input Area */}
          <Box
            sx={{
              p: 2,
              borderTop: 1,
              borderColor: "divider",
              bgcolor: "background.paper",
            }}
          >
            <Box sx={{ display: "flex", gap: 1 }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Type your message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleSendMessage();
                  }
                }}
                size="small"
              />
              <Button
                variant="contained"
                endIcon={<SendIcon />}
                onClick={handleSendMessage}
              >
                Send
              </Button>
            </Box>
          </Box>
        </Paper>
      </Slide>
    </Box>
  );
};

export default Step4Chat;
