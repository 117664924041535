import React from "react";
import {
  Select,
  MenuItem,
  CircularProgress,
  FormControl,
  InputLabel,
  Alert,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { useClient } from "./ClientContext";
import { API_BASE_URL } from "../config";

const ClientSelector = () => {
  const {
    clients,
    selectedClient,
    setSelectedClient,
    loading,
    error,
    refreshClients,
  } = useClient();

  const handleChange = (event) => {
    const client = clients.find((c) => c.id === event.target.value);
    setSelectedClient(client);
  };

  const fetchClients = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/clients`);
      // ... rest of the function
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", padding: "20px" }}>
        <CircularProgress size={24} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ padding: "20px" }}>
        <Alert
          severity="error"
          action={
            <Button color="inherit" size="small" onClick={refreshClients}>
              Retry
            </Button>
          }
        >
          {error}
        </Alert>
      </Box>
    );
  }

  return (
    <FormControl fullWidth>
      <InputLabel>Select Client</InputLabel>
      <Select
        value={selectedClient?.id || ""}
        onChange={handleChange}
        label="Select Client"
        disabled={!clients?.length}
        sx={{ minWidth: 200 }}
      >
        {!clients?.length && (
          <MenuItem disabled value="">
            No clients available
          </MenuItem>
        )}
        {clients?.map((client) => (
          <MenuItem
            key={client.id}
            value={client.id}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{client.name}</span>
            <Typography variant="caption" color="textSecondary" sx={{ ml: 2 }}>
              {client.industry}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ClientSelector;
