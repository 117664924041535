import React, { useState } from "react";
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { API_BASE_URL } from "../config";
import { useClient } from "./ClientContext";
import NotificationSnackbar from "./NotificationSnackbar";
import { api } from "../api";

const ClientRegistry = () => {
  const { clients, fetchClients } = useClient();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    industry: "",
    location: "",
    contactPerson: "",
    email: "",
    phone: "",
    targetAudience: "",
    keyMessages: "",
    preferences: "",
    boilerplate: "",
    spokesperson_name: "",
    spokesperson_title: "",
    contact_info: "",
    social_media_handles: {},
    brand_guidelines: "",
  });
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleOpenDialog = (client = null) => {
    if (client) {
      setFormData(client);
      setSelectedClient(client);
    } else {
      setFormData({
        name: "",
        industry: "",
        location: "",
        contactPerson: "",
        email: "",
        phone: "",
        targetAudience: "",
        keyMessages: "",
        preferences: "",
        boilerplate: "",
        spokesperson_name: "",
        spokesperson_title: "",
        contact_info: "",
        social_media_handles: {},
        brand_guidelines: "",
      });
      setSelectedClient(null);
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedClient(null);
  };

  const handleSubmit = async () => {
    try {
      if (selectedClient) {
        await handleUpdateClient(selectedClient.id, formData);
      } else {
        await handleCreateClient(formData);
      }
      await fetchClients();
      handleCloseDialog();
    } catch (error) {
      console.error("Error saving client:", error);
      showNotification(error.message, "error");
    }
  };

  const handleDelete = async (clientId) => {
    if (window.confirm("Are you sure you want to delete this client?")) {
      try {
        const response = await fetch(`${API_BASE_URL}/clients/${clientId}`, {
          method: "DELETE",
        });

        if (!response.ok) {
          const errorData = await response.json().catch(() => null);
          throw new Error(errorData?.detail || "Failed to delete client");
        }

        await fetchClients();
        showNotification("Client deleted successfully!");
      } catch (error) {
        console.error("Error deleting client:", error);
        showNotification(error.message || "Failed to delete client", "error");
      }
    }
  };

  const handleCloseNotification = () => {
    setNotification((prev) => ({ ...prev, open: false }));
  };

  const showNotification = (message, severity = "success") => {
    setNotification({
      open: true,
      message,
      severity,
    });
  };

  const handleCreateClient = async (clientData) => {
    try {
      await api.createClient(clientData);
      showNotification("Client created successfully!");
    } catch (error) {
      showNotification(error.message, "error");
      throw error;
    }
  };

  const handleUpdateClient = async (id, clientData) => {
    try {
      await api.updateClient(id, clientData);
      showNotification("Client updated successfully!");
    } catch (error) {
      showNotification(error.message, "error");
      throw error;
    }
  };

  return (
    <Container className="client-registry-container">
      <Box className="header-container">
        <Typography className="page-title">Client Registry</Typography>
        <Button
          variant="contained"
          className="add-button"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
        >
          Add Client
        </Button>
      </Box>

      <TableContainer component={Paper} className="table-container">
        <Table>
          <TableHead>
            <TableRow className="table-header">
              <TableCell className="table-header-cell">Name</TableCell>
              <TableCell className="table-header-cell">Industry</TableCell>
              <TableCell className="table-header-cell">Location</TableCell>
              <TableCell className="table-header-cell">
                Contact Person
              </TableCell>
              <TableCell className="table-header-cell">Email</TableCell>
              <TableCell className="table-header-cell">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients && clients.length > 0 ? (
              clients.map((client) => (
                <TableRow key={client.id}>
                  <TableCell className="table-cell">{client.name}</TableCell>
                  <TableCell className="table-cell">
                    {client.industry}
                  </TableCell>
                  <TableCell className="table-cell">
                    {client.location}
                  </TableCell>
                  <TableCell className="table-cell">
                    {client.contactPerson}
                  </TableCell>
                  <TableCell className="table-cell">{client.email}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpenDialog(client)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(client.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No clients found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        className="dialog"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {selectedClient ? "Edit Client" : "Add New Client"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "grid", gap: 2, pt: 2 }}>
            <TextField
              className="dialog-input"
              label="Client Name"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              fullWidth
            />
            <TextField
              className="dialog-input"
              label="Industry"
              value={formData.industry}
              onChange={(e) =>
                setFormData({ ...formData, industry: e.target.value })
              }
              fullWidth
            />
            <TextField
              className="dialog-input"
              label="Location"
              value={formData.location}
              onChange={(e) =>
                setFormData({ ...formData, location: e.target.value })
              }
              fullWidth
            />
            <TextField
              className="dialog-input"
              label="Contact Person"
              value={formData.contactPerson}
              onChange={(e) =>
                setFormData({ ...formData, contactPerson: e.target.value })
              }
              fullWidth
            />
            <TextField
              className="dialog-input"
              label="Email"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              fullWidth
            />
            <TextField
              className="dialog-input"
              label="Phone"
              value={formData.phone}
              onChange={(e) =>
                setFormData({ ...formData, phone: e.target.value })
              }
              fullWidth
            />
            <TextField
              className="dialog-input"
              label="Spokesperson Name"
              value={formData.spokesperson_name}
              onChange={(e) =>
                setFormData({ ...formData, spokesperson_name: e.target.value })
              }
              fullWidth
            />
            <TextField
              className="dialog-input"
              label="Spokesperson Title"
              value={formData.spokesperson_title}
              onChange={(e) =>
                setFormData({ ...formData, spokesperson_title: e.target.value })
              }
              fullWidth
            />
            <TextField
              className="dialog-input"
              label="Contact Information"
              value={formData.contact_info}
              onChange={(e) =>
                setFormData({ ...formData, contact_info: e.target.value })
              }
              fullWidth
            />
            <TextField
              className="dialog-input"
              label="Target Audience"
              value={formData.targetAudience}
              onChange={(e) =>
                setFormData({ ...formData, targetAudience: e.target.value })
              }
              fullWidth
              multiline
              rows={2}
            />
            <TextField
              className="dialog-input"
              label="Key Messages"
              value={formData.keyMessages}
              onChange={(e) =>
                setFormData({ ...formData, keyMessages: e.target.value })
              }
              fullWidth
              multiline
              rows={3}
            />
            <TextField
              className="dialog-input"
              label="Company Boilerplate"
              value={formData.boilerplate}
              onChange={(e) =>
                setFormData({ ...formData, boilerplate: e.target.value })
              }
              fullWidth
              multiline
              rows={4}
              helperText="Standard company description for press releases and official communications"
            />
            <TextField
              className="dialog-input"
              label="Brand Guidelines"
              value={formData.brand_guidelines}
              onChange={(e) =>
                setFormData({ ...formData, brand_guidelines: e.target.value })
              }
              fullWidth
              multiline
              rows={3}
            />
            <TextField
              className="dialog-input"
              label="Preferences"
              value={formData.preferences}
              onChange={(e) =>
                setFormData({ ...formData, preferences: e.target.value })
              }
              fullWidth
              multiline
              rows={2}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            {selectedClient ? "Update" : "Add"}
          </Button>
        </DialogActions>
      </Dialog>

      <NotificationSnackbar
        open={notification.open}
        message={notification.message}
        severity={notification.severity}
        onClose={handleCloseNotification}
      />
    </Container>
  );
};

export default ClientRegistry;
