import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import NavigationBar from "./components/NavigationBar";
import Reports from "./components/Reports";
import KnowledgeDiscoverySystem from "./components/KnowledgeDiscoverySystem";
import VisualContentCreator from "./components/VisualContentCreator";
import ContentManagerDashboard from "./components/ContentManagerDashboard";
import ClientsRegistry from "./components/ClientsRegistry";
import AgentsPage from "./components/AgentsPage";
import "./App.css";
import { ClientProvider } from "./components/ClientContext";

function App() {
  return (
    <ClientProvider>
      <Router>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
            background: `linear-gradient(135deg, 
              #1B3C59 0%, 
              #2B4C6F 25%, 
              #406E8E 50%, 
              #4A7A9F 75%, 
              #5B92BB 100%)`,
            overflow: "hidden",
          }}
        >
          <NavigationBar />
          <Box
            sx={{
              flex: 1,
              p: 3,
              overflow: "auto",
            }}
          >
            <Routes>
              <Route path="/" element={<ContentManagerDashboard />} />
              <Route path="/reports" element={<Reports />} />
              <Route
                path="/knowledge-discovery"
                element={<KnowledgeDiscoverySystem />}
              />
              <Route
                path="/visual-creator"
                element={<VisualContentCreator />}
              />
              <Route path="/agents" element={<AgentsPage />} />
              <Route path="/clients" element={<ClientsRegistry />} />
            </Routes>
          </Box>
        </Box>
      </Router>
    </ClientProvider>
  );
}

export default App;
