import React, { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Paper,
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Checkbox,
  Alert,
  FormGroup,
  Card,
  CardContent,
  LinearProgress,
  CircularProgress,
  Grid,
  Collapse,
} from "@mui/material";
import {
  BarChart as BarChartIcon,
  Storage as StorageIcon,
  Description as DescriptionIcon,
  Chat as ChatIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from "@mui/icons-material";
import Step1Analysis from "./Steps/Step1Analysis";
import Step2DataInput from "./Steps/Step2DataInput";
import Step3Report from "./Steps/Step3Report";
import Step4Chat from "./Steps/Step4Chat";
import { API_BASE_URL } from "../config";
const Reports = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [analysisResults, setAnalysisResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    analysisType: "",
    dataInput: "",
    email: "",
    preferences: [],
  });
  const [errors, setErrors] = useState({});
  const [showSteps, setShowSteps] = useState(true);

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
    if (errors[field]) {
      setErrors((prev) => ({
        ...prev,
        [field]: null,
      }));
    }
  };

  const handlePreferenceChange = (pref, checked) => {
    setFormData((prev) => ({
      ...prev,
      preferences: checked
        ? [...prev.preferences, pref]
        : prev.preferences.filter((p) => p !== pref),
    }));
  };

  const validateStep = (stepNumber) => {
    const newErrors = {};

    switch (stepNumber) {
      case 0:
        if (!formData.analysisType) {
          newErrors.analysisType = "Please select an analysis type";
        }
        break;
      case 1:
        if (!formData.dataInput?.trim()) {
          newErrors.dataInput = "Please input some data";
        }
        break;
      case 2:
        if (!formData.email) {
          newErrors.email = "Please enter your email";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          newErrors.email = "Please enter a valid email";
        }
        break;
      default:
        break;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const fetchAnalysisResults = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/analyze`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          analysisType: formData.analysisType,
          data: formData.dataInput,
        }),
      });

      const data = await response.json();
      setAnalysisResults(data);
    } catch (error) {
      console.error("Analysis failed:", error);
      setErrors((prev) => ({
        ...prev,
        analysis: "Failed to process analysis. Please try again.",
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleNext = async () => {
    if (validateStep(activeStep)) {
      if (activeStep === 1) {
        await fetchAnalysisResults();
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const steps = [
    {
      label: "Choose your Analysis Type",
      description: "Select the type of analysis that best fits your needs",
      icon: <BarChartIcon />,
      content: (
        <Step1Analysis
          formData={formData}
          handleInputChange={handleInputChange}
          errors={errors}
        />
      ),
    },
    {
      label: "Input your data",
      description: "Upload or paste your data for analysis",
      icon: <StorageIcon />,
      content: (
        <Step2DataInput
          formData={formData}
          handleInputChange={handleInputChange}
          errors={errors}
        />
      ),
    },
    {
      label: "Get your report",
      description: "Receive detailed insights and visualizations",
      icon: <DescriptionIcon />,
      content: (
        <Step3Report
          formData={formData}
          handleInputChange={handleInputChange}
          handlePreferenceChange={handlePreferenceChange}
          errors={errors}
          analysisResults={analysisResults}
        />
      ),
    },
    {
      label: "Chat with Dan",
      description: "Understand your results and plan next steps",
      icon: <ChatIcon />,
      content: <Step4Chat analysisResults={analysisResults} />,
    },
  ];

  const ResultsPanel = () => (
    <Paper
      elevation={3}
      sx={{
        p: 3,
        height: "100%",
        minHeight: "600px",
        bgcolor: "background.paper",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h5" gutterBottom>
        Analysis Results
      </Typography>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <CircularProgress />
        </Box>
      ) : analysisResults ? (
        <Box sx={{ flex: 1, overflow: "auto" }}>
          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Key Insights
            </Typography>
            <Paper sx={{ p: 2, bgcolor: "background.default" }}>
              <Typography>
                {JSON.stringify(analysisResults, null, 2)}
              </Typography>
            </Paper>
          </Box>

          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" gutterBottom>
              Visualizations
            </Typography>
            <Paper
              sx={{ p: 2, bgcolor: "background.default", height: "200px" }}
            >
              <Typography color="text.secondary" align="center">
                Visualization Area
              </Typography>
            </Paper>
          </Box>

          <Box>
            <Typography variant="h6" gutterBottom>
              Recommendations
            </Typography>
            <Paper sx={{ p: 2, bgcolor: "background.default" }}>
              <Typography color="text.secondary">
                Based on the analysis, we recommend...
              </Typography>
            </Paper>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            color: "text.secondary",
          }}
        >
          <Typography>
            Complete the steps to see your analysis results
          </Typography>
        </Box>
      )}
    </Paper>
  );

  return (
    <Box sx={{ maxWidth: 1400, mx: "auto", mt: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={activeStep >= 2 ? 7 : 12}>
          {activeStep === 3 && (
            <Button
              variant="outlined"
              size="small"
              onClick={() => setShowSteps(!showSteps)}
              sx={{ mb: 2 }}
              startIcon={
                showSteps ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />
              }
            >
              {showSteps ? "Hide Steps" : "Show Steps"}
            </Button>
          )}

          <Collapse in={activeStep !== 3 || showSteps}>
            <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
              <Box sx={{ mb: 4 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                  Generate Reports
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Follow these steps to set up your data analysis project
                </Typography>
              </Box>

              <Box sx={{ mb: 4 }}>
                <LinearProgress
                  variant="determinate"
                  value={(activeStep / (steps.length - 1)) * 100}
                  sx={{ height: 8, borderRadius: 4 }}
                />
              </Box>

              <Stepper
                activeStep={activeStep}
                orientation="vertical"
                sx={{
                  "& .MuiStepLabel-root": {
                    padding: 2,
                    "&:hover": {
                      bgcolor: "action.hover",
                      borderRadius: 1,
                    },
                  },
                  "& .MuiStepContent-root": {
                    borderLeft: "2px solid",
                    borderLeftColor: "primary.main",
                    ml: 2,
                  },
                }}
              >
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel
                      StepIconComponent={() => (
                        <Box
                          sx={{
                            color:
                              activeStep >= index ? "primary.main" : "grey.400",
                            display: "flex",
                            alignItems: "center",
                            bgcolor:
                              activeStep >= index
                                ? "primary.lighter"
                                : "grey.100",
                            p: 1,
                            borderRadius: "50%",
                          }}
                        >
                          {step.icon}
                        </Box>
                      )}
                    >
                      <Typography variant="h6">{step.label}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {step.description}
                      </Typography>
                    </StepLabel>
                    <StepContent>
                      <Paper
                        elevation={0}
                        sx={{
                          p: 3,
                          bgcolor: "background.default",
                          borderRadius: 2,
                          mt: 2,
                        }}
                      >
                        {index < 3 && step.content}
                      </Paper>
                      <Box sx={{ mb: 2, mt: 3, display: "flex", gap: 1 }}>
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ px: 4 }}
                        >
                          {index === steps.length - 1 ? "Finish" : "Continue"}
                        </Button>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          variant="outlined"
                        >
                          Back
                        </Button>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Paper>
          </Collapse>

          {activeStep === 3 && (
            <Box>
              <Step4Chat analysisResults={analysisResults} />
            </Box>
          )}
        </Grid>

        {activeStep >= 2 && (
          <Grid item xs={12} md={5}>
            <ResultsPanel />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Reports;
