import React, { useEffect, useState } from "react";
import { getSocket, cleanupWebSocket } from "../api";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
  LinearProgress,
} from "@mui/material";
import { CheckCircle, Error, Info, ArrowForward } from "@mui/icons-material";
import "./OutputDisplay.css";

const OutputDisplay = ({ outputs }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const socket = getSocket();

    socket.on("connect", () => {
      setIsConnected(true);
    });

    socket.on("disconnect", () => {
      setIsConnected(false);
    });

    socket.on("crew_output", (data) => {
      console.log("Received crew output:", data);
      setMessages((prev) => [...prev, data]);
    });

    socket.on("crew_error", (error) => {
      console.error("Crew error:", error);
      setMessages((prev) => [...prev, { type: "error", ...error }]);
    });

    return () => {
      cleanupWebSocket();
    };
  }, []);

  const formatMessage = (msg) => {
    if (typeof msg === "string") return msg;

    switch (msg.type) {
      case "status":
        return msg.message;
      case "agent_output":
        if (msg.data && typeof msg.data === "object") {
          if (msg.data.type === "error") {
            return `Error: ${msg.data.message}`;
          }
          return `${msg.data.agent || "Agent"}: ${
            msg.data.message || JSON.stringify(msg.data)
          }`;
        }
        return JSON.stringify(msg.data);
      case "error":
        return `Error: ${msg.error || msg.message}`;
      case "result":
        if (msg.data && msg.data.raw) {
          return msg.data.raw;
        }
        if (msg.data && msg.data.tasks_output) {
          return msg.data.tasks_output
            .map((task) => `Task Complete: ${task}`)
            .join("\n");
        }
        return "Task completed successfully";
      default:
        return JSON.stringify(msg);
    }
  };

  const getMessageIcon = (msg) => {
    if (msg.type === "agent_output" && msg.data?.type === "error") {
      return <Error className="message-icon error" />;
    }

    switch (msg.type) {
      case "status":
        return <Info className="message-icon info" />;
      case "error":
        return <Error className="message-icon error" />;
      case "result":
        return <CheckCircle className="message-icon success" />;
      case "agent_output":
        return <ArrowForward className="message-icon agent" />;
      case "message":
        return <Info className="message-icon info" />;
      default:
        return null;
    }
  };

  const getMessageClass = (msg) => {
    if (msg.type === "agent_output" && msg.data?.type === "error") {
      return "error";
    }

    switch (msg.type) {
      case "error":
        return "error";
      case "status":
        return "info";
      case "result":
        return "success";
      case "agent_output":
        return "agent";
      case "message":
        return "info";
      default:
        return "info";
    }
  };

  return (
    <CardContent className="output-card">
      <Box className="output-header">
        <span
          className={`connection-indicator ${
            isConnected ? "connected" : "disconnected"
          }`}
        />
        <Typography variant="h6">Live Output</Typography>
        {isConnected && <LinearProgress className="progress-bar" />}
      </Box>
      <Box className="output-container">
        {messages.map((msg, index) => (
          <Box
            key={index}
            className={`message-wrapper ${getMessageClass(msg)}`}
          >
            {getMessageIcon(msg)}
            <div className="message-content">
              <Typography className="message-text">
                {formatMessage(msg)}
              </Typography>
              {msg.timestamp && (
                <Typography variant="caption" className="timestamp">
                  {new Date(msg.timestamp).toLocaleTimeString()}
                </Typography>
              )}
            </div>
          </Box>
        ))}
        {outputs &&
          outputs.map((output, index) => (
            <Box key={`output-${index}`} className="message-wrapper info">
              <Info className="message-icon info" />
              <div className="message-content">
                <Typography className="message-text">
                  {formatMessage(output)}
                </Typography>
              </div>
            </Box>
          ))}
      </Box>
    </CardContent>
  );
};

export default OutputDisplay;
