import React from "react";
import { Box, TextField, Button, Alert } from "@mui/material";

const Step2DataInput = ({ formData, handleInputChange, errors }) => {
  return (
    <Box sx={{ mt: 2 }}>
      <TextField
        fullWidth
        multiline
        rows={4}
        value={formData.dataInput}
        onChange={(e) => handleInputChange("dataInput", e.target.value)}
        placeholder="Paste your data here..."
        variant="outlined"
      />
      <Button variant="outlined" sx={{ mt: 2 }} component="label">
        Upload CSV
        <input type="file" hidden accept=".csv" />
      </Button>
      {errors.dataInput && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {errors.dataInput}
        </Alert>
      )}
    </Box>
  );
};

export default Step2DataInput;
